import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchResultsHome } from "../Components/SearchResultsHome";
import { HomePageStyle } from "../Components/Styles/HomePageStyle";
import TimesSVG from "../assets/times.svg";
import { useEnvironment } from "../States/EnvoirementState";
import { Person } from "../Types/Person";
import axios from "axios";
import { getEnvURl } from "../States/EnvURLS";
import SearchPerson from "../Repositories/SearchPerson";
import ShowPersonRequest from "../Repositories/ShowPerson";
import moment from "moment/moment";
import ShowPerson from '../Components/ShowPerson';
import QRSVG from '../assets/qrcode.svg'
import QRReader from "../Components/QRReader";
import Swal from 'sweetalert2'
import SettingsSVG from '../assets/settings.svg';
import withReactContent from "sweetalert2-react-content";
import { Alert, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { changeStation, getAvailableOptions } from "../Repositories/ChangeENVRequest";
import addPersonTermin from "../Repositories/addPersonTermin";

type Props = {
    showHideBottomBar: (status: boolean) => void;
    forceOpenUser?: Person;
    personOpenCallback: () => void;
    changePage: (whereTo: string) => void;
}

export default function HomePage(props: Props) {

    const [idInput, setIdInput] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState<Person[]>([]);
    const [resultsOpen, setResultsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('done');
    const [payment, setPayment] = useState(true);
    const [child, setChild] = useState(false);
    const [foundPerson, setFoundPerson] = useState<Person | null>(null);
    const [selectedTest, setSelectedTest] = useState<number>();
    const SAlert = withReactContent(Swal)
    const qrInputRef = useRef<HTMLInputElement>(null);
    const searchParams = new URLSearchParams(document.location.search)

    useEffect(() => {
        if (!foundPerson) {
            focusQRInput()
        }

    }, [foundPerson]);

    function focusQRInput() {
        if (qrInputRef.current) {
            setIdInput("")
            qrInputRef.current.focus();
        }
    }

    useEffect(() => {

        if (props.forceOpenUser !== undefined) {
            const forceOpen = JSON.parse(JSON.stringify(props.forceOpenUser));
            showPerson(forceOpen);
            props.personOpenCallback();
        }
    }, [props.forceOpenUser]);



    const searchPerson = useCallback(async (inputData?: string) => {

        let search = searchInput;
        if (inputData !== undefined) {
            search = inputData;
        }
        const persons = await SearchPerson(search);
        if (persons === false) {
            //ERROR WHILE SENDING REQUEST
            return;
        }
        setSearchResults(persons);
        setResultsOpen(true);
    }, [searchInput]);

    const showPerson = useCallback(async (xPerson?: Person, fromQr?: string) => {
        console.log('XPERSON', xPerson)
        console.log('fromQr', fromQr)
        let personUUID = null;

        if (fromQr !== undefined) {
            personUUID = fromQr;
        } else if (xPerson !== undefined) {
            //@ts-ignore
            personUUID = xPerson.transaction
        } else {
            selectTestForUser()
            return;
        }

        let personRequest = await ShowPersonRequest(personUUID);

        if (personRequest === false) {
            selectTestForUser(personUUID)
            return;
        }

        const person: Person = personRequest[0];
        //@ts-ignore

        if (person === undefined) {
            selectTestForUser(personUUID)
            return;
        }

        setFoundPerson(person);

        setSearchResults([]);
        setResultsOpen(false);
        setPayment(false);
        setPaymentStatus('done');
        props.showHideBottomBar(false);
    }, []);

    function selectTestForUser(personUuid?: string) {
        if (personUuid === undefined) {
            alert('Kein valides Ticket');
            return;
        }

        SAlert.fire({
            title: "Keine Buchung gefunden",
            text: "Diese Person hat heute bereits einen Test gemacht oder ist nicht angemeldet. Bitten Sie die Person sich erneut anzumelden",
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: "#618e9c",
            cancelButtonText: 'Abbrechen',
        }).then((confirmResult) => {

            // @ts-ignore
            if (confirmResult.dismiss === "cancel") {

            }
        })

    }


    const openSearchedPerson = useCallback((person: Person) => {


        if (person.Status === 'OPENED') {
            setSearchResults([]);
            setResultsOpen(false);
            setPayment(false);
            setPaymentStatus('done');
            setFoundPerson(person);
            props.showHideBottomBar(false);
        } else {
            selectTestForUser(person.uuid)
        }
    }, []);


    const onBack = useCallback(() => {
        setFoundPerson(null);
        setResultsOpen(false);
        setSearchResults([]);
        setSearchInput('');
        props.showHideBottomBar(true);
    }, []);

    const onKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement> | undefined, pressButton: () => void) => {
        if (event === undefined) {
            return;
        }
        const code = event.keyCode || event.which || event.code;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            pressButton()
        }
    }, [searchPerson]);

    return (
        <React.Fragment>

            {(resultsOpen && searchResults.length > 0 && isLoading !== true) ? (
                <SearchResultsHome onPressBack={() => { setResultsOpen(false); setSearchInput('') }} onRequestOpen={(person) => openSearchedPerson(person)} searchResults={searchResults} />
            ) : (
                <React.Fragment>
                    {isLoading && <div style={{ backgroundColor: 'white', position: 'absolute', display: 'flex', top: '20%', width: '100%', height: '60%', justifyContent: 'center', alignItems: 'center', }}>
                        Lädt...

                    </div>}
                    {foundPerson !== null ? <ShowPerson onBack={onBack} paymentStatus={paymentStatus} person={foundPerson} updatePaymentStatus={(status, payed) => { setPayment(payed); setPaymentStatus(status); }} payment={payment} /> : (
                        <React.Fragment>
                            {/* <img onClick={() => props.changePage('settings')} src={SettingsSVG} width={25} height={25} style={{ position: "absolute", top: 10, right: 10 }} /> */}
                            <div style={HomePageStyle.mainDiv}>
                                <div style={HomePageStyle.inputButtonHolder}>
                                    <div style={HomePageStyle.inputHolder}>
                                        <input onKeyPress={(event) => onKeyPress(event, searchPerson)} value={searchInput} onChange={(event) => setSearchInput(event.target.value)} placeholder={'Name o. Vorname'} style={HomePageStyle.inputStyle} />
                                        <div onClick={() => setSearchInput('')} style={HomePageStyle.inputXButton}>
                                            <img src={TimesSVG} style={HomePageStyle.timesIcon} />
                                        </div>
                                    </div>
                                    <div onClick={() => searchPerson()} style={HomePageStyle.searchHolder}>
                                        <p style={HomePageStyle.searchText}>Suchen</p>
                                    </div>
                                </div>
                                <div style={HomePageStyle.inputButtonHolder}>
                                    <div style={HomePageStyle.inputHolder}>
                                        <input inputMode="none" type="text" ref={qrInputRef} onKeyPress={(event) => onKeyPress(event, () => showPerson(undefined, idInput))} value={idInput} onChange={(event) => setIdInput(event.target.value)} placeholder={'QR-CODE / ID'} style={HomePageStyle.inputStyle} />
                                        <div onClick={() => { setIdInput(''); focusQRInput() }} style={HomePageStyle.inputXButton}>
                                            <img src={TimesSVG} style={HomePageStyle.timesIcon} />
                                        </div>
                                    </div>
                                    <div onClick={() => showPerson(undefined, idInput)} style={HomePageStyle.searchHolder}>
                                        <p style={HomePageStyle.searchText}>
                                            <img src={QRSVG} width={30} height={30} />
                                        </p>
                                    </div>
                                </div>

                                {/* <QRReader onRead={(text) => {
                                    showPerson(undefined, text);
                                }} /> */}

                                <div onClick={() => props.changePage('termine')} style={{ ...HomePageStyle.searchHolder, textAlign: 'center' }}>
                                    <p style={HomePageStyle.searchText}>In Terminen suchen</p>
                                </div>
                            </div>
                        </React.Fragment>

                    )}

                </React.Fragment>

            )
            }
        </React.Fragment >
    )


}
